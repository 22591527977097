import { sortOrders } from "@/enums/main/sortOrders";
import { IPromoCodesSort } from "../types/promoCodesTypes";

export const getPromoCodesSortConfig = (
  currentSortKeyLocationName: string
): IPromoCodesSort => ({
  byTitle: {
    order: sortOrders.asc,
    keyName: currentSortKeyLocationName,
    keyNameInModel: "title"
  }
});
