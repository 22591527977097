import { ITableBodyRow } from "@/components/Table/ts/interfaces/TableStructure";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";
import { formatDate, formatDateUntill } from "@/hooks/payments/usePayments";
import { IPromoCode } from "@/store/interfaces/promo-codes/promoCodes";

export const generatePromoCodesRows = (sorting: any) => {
  return function(promoCodesList: IPromoCode[] = []): ITableBodyRow[] {
    return promoCodesList.map(
      (promo: IPromoCode): ITableBodyRow => {
        const {
          id,
          title,
          discountType,
          activeTo,
          isUsed,
          active,
          unique,
          reusableCount
        } = promo;
        const { byName } = sorting.value;
        const sort = {
          keyName: byName?.keyName,
          value: title,
          order: byName?.order
        };
        return {
          cells: [
            {
              sort,
              label: title
            },
            {
              label: formatDateUntill(activeTo)
            },
            {
              label: discountType
            },
            {
              badge: {
                type: isUsed ? badgeTypes.lightSecondary : badgeTypes.danger,
                label: isUsed
                  ? unique
                    ? "Used"
                    : `Used ${reusableCount.toString() +
                        (reusableCount === 1 ? " time" : " times")}`
                  : "Not Used"
              }
            },
            {
              badge: {
                type: active ? badgeTypes.lightSecondary : badgeTypes.danger,
                label: active ? "Active" : "Inactive"
              }
            },
            {
              userActions: true
            }
          ],
          id
        };
      }
    );
  };
};
