import { getDropDownTemplate } from "@/components/ui/Country/getDropDownTemplate";
export const mapDropDownSelect = (data: any, temp: number[]) => {
  return data.map((u: any) => {
    return {
      ...u,
      name: u.name,
      template: getDropDownTemplate(
        u.id,
        u.name ?? u.title ?? u.email ?? u.offering_title,
        u.icon ?? ""
      ),
      checked: temp.includes(u.id)
    };
  });
};
