
import { defineComponent } from "@vue/runtime-core";
import { usePromoCodes } from "@/hooks/promo-codes/usePromoCodes";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import CreatePromoCodesModal from "@/components/ui/Modal/Dialog/instances/CreatePromoCodesModal.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import DeleteConfirmDialog from "@/components/ui/Modal/Dialog/instances/DeleteConfirmDialog.vue";
import { PromoCodesDto } from "@/api/interfaces/promo-codes/promo-codes.dto";

export default defineComponent({
  name: "PromoCodes",
  components: {
    AppToolbarTable,
    CreatePromoCodesModal,
    DeleteConfirmDialog
  },
  setup() {
    const { fetchPromoCodes, getDetailsForModal } = usePromoCodes();
    fetchPromoCodes();
    getDetailsForModal();
    const store = useStore();
    return {
      ...usePromoCodes(),
      countries: computed(() => store.getters.locationPackages("countries")),
      regions: computed(() => store.getters.locationPackages("regions")),
      subregions: computed(() => store.getters.locationPackages("subregions")),
      // bundles: computed(() => store.getters.bundles),
      plans: computed(() => store.getters.esimPlansParrent),
      users: computed(() => store.getters.accountsList),
      providers: computed(() => store.getters.providers)
    };
  },
  data() {
    return {
      editData: {} as PromoCodesDto,
      openedForm: false,
      isLoading: false,
      deleteForm: false,
      deleteId: -1,
      bundles: []
    };
  },
  watch: {
    providers: {
      handler(data) {
        if (!data.length) return [];
        this.bundles = data.flatMap((provider: any) => {
          if (provider.multitelBundles.length) {
            return provider.multitelBundles.map((bundle: any) => ({
              ...bundle,
              providerTypeId: provider.id
            }));
          }
          if (provider.resellerBundles.length) {
            return provider.resellerBundles.map((bundle: any) => ({
              ...bundle,
              providerTypeId: provider.id
            }));
          }
          return [];
        });
      }
    }
  },
  methods: {
    cleanData() {
      this.openedForm = false;
      this.deleteForm = false;
      this.isLoading = false;
      this.deleteId = -1;
      this.editData = {} as PromoCodesDto;
    },
    async onSubmit(data: PromoCodesDto, id: number) {
      this.openedForm = false;
      await this.actionWithPromoCodes(data, id);
      await this.fetchPromoCodes();
      this.cleanData();
    },
    onEdit(id: number) {
      const list: PromoCodesDto[] = this.promoCodesModel.map(
        (el: any) => ({ ...el } as PromoCodesDto)
      );
      this.editData =
        list?.find((promo: PromoCodesDto) => promo.id === id) ||
        ({} as PromoCodesDto);
      this.openedForm = true;
    },
    onRemove(id: number) {
      this.deleteId = id;
      this.deleteForm = true;
    },
    async deleteHandler() {
      this.isLoading = true;
      await this.actionWithPromoCodes(null, this.deleteId);
      this.fetchPromoCodes();
      this.cleanData();
    }
  }
});
