<template>
  <div class="multiselect-wrapper" :class="{ disabled }">
    <div class="multiselect-input">
      <div
        v-if="checkedItems.showItems.length"
        style="
          max-width: 100%;
          overflow: hidden;"
        class="d-flex"
      >
        <ul class="multiselect-selected-list">
          <li
            v-for="item in checkedItems.showItems"
            :key="item.id"
            class="multiselect-selected-list-item"
          >
            <MultiselectItem :html="item.template" />
          </li>
        </ul>

        <span v-if="checkedItems.moreCount > 0" class="more">
          + {{ checkedItems.moreCount }}
        </span>
      </div>
      <span v-else class="multiselect-placeholder">
        {{ placeholder }}
      </span>

      <TooltipDropdown :disabled="disabled" :width="width" strategy="absolute">
        <template #content>
          <div class="multiselect-select-wrapper">
            <SearchControl
              v-if="searchHandler"
              :on-search="searchHandler"
              name="multiselect-search"
              field-type="inline-field"
            />

            <ul class="select-list">
              <li v-for="item in tooltipList" :key="item.id">
                <label
                  :for="`${type}-${item.id}`"
                  class="d-flex justify-content-between align-items-center pointer"
                >
                  <MultiselectItem :html="item.template" />
                  <label class="d-block">
                    <AppCheckbox
                      :id="`${type}-${item.id}`"
                      v-model="item.checked"
                      class="multiselect-checkbox"
                      type="checkbox"
                      :name="item.id"
                      @change="changeHandler(item.id)"
                    />
                  </label>
                </label>
              </li>
            </ul>
          </div>
        </template>
      </TooltipDropdown>
    </div>
  </div>
</template>

<script lang="js">
import TooltipDropdown from "@/components/ui/TooltipDropdown";
import MultiselectItem from "@/components/ui/MultiSelect/MultiselectItem";
import AppCheckbox from "@/components/Form/AppCheckbox";
import SearchControl from "@/components/Form/SearchControl";

export default {
  name: "MultiSelect",
  components: {
    SearchControl,
    AppCheckbox,
    MultiselectItem,
    TooltipDropdown
  },

  props: {
    placeholder: { type: String, required: false, default: "" },
    list: { type: Array, required: true },
    showCount: { type: Number, default: 2 },
    disabled: { type: Boolean, default: false },
    selected: { type: Array, default: () => [] },
    searchHandler: { type: Function },
    type: { type: String, default: "" },
    filteredList: {
      type: [Array, null],
      default: null,
      required: false
    },
    width: {
      type: String,
      default: "300px"
    },
  },
  emits: {
    onChange: { type: Function, required: true }
  },

  data() {
    return {
      checked: this.selected.map(s => s.id)
    };
  },

  computed: {
    updatedList() {
      return this.filteredList === this.list ? this.list : this.filteredList;
    },

    tooltipList() {
      return this.updatedList;
    },

    checkedItems() {
      const items = this.updatedList.filter(({ checked }) => checked);
      return {
        items,
        showItems: items.slice(0, 2),
        moreCount: items.length - 2
      };
    }
  },

  methods: {
    changeHandler(id) {
      this.$emit("onChange", id);
    }
  }
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}

.select-list {
  min-width: 220px;
  max-height: 330px;
  overflow: auto;

  @extend %hideScrollBar;

  & > li {
    padding: 0.3em 0;
    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.form-control-wrapper + .select-list {
  margin-top: 1.2em;
}

.multiselect-select-wrapper {
  padding: 1em;
}

.multiselect-input {
  width: 100%;
  height: $field-height;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  padding: 0 $field-padding-x;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .more {
    color: $color-primary-blue;
    margin-left: 1.7rem;
  }
}
.multiselect-wrapper.disabled {
  pointer-events: "none";
  opacity: 0.6;
}
.multiselect-selected-list {
  display: flex;
  align-items: center;

  & > li:not(:last-child) {
    margin-right: 1.4rem;
  }
}
</style>
