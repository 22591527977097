<template>
  <span class="multiselect-item-tpl" v-html="html" />
</template>
<script>
export default {
  props: {
    html: { type: String, required: true }
  }
};
</script>
