<template>
  <MultiSelect
    :width="width"
    :disabled="disabled"
    :list="list"
    :filtered-list="getfilteredCountriesList"
    :selected="selectedList"
    :type="type"
    :search-handler="withSearch ? searchHandler : null"
    :placeholder="placeholder"
    @onChange="selectHandler"
  />
</template>

<script>
import MultiSelect from "@/components/ui/MultiSelect/MultiSelect.vue";
import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "CountrySelect",
  components: { MultiSelect },
  props: {
    onSelect: { type: Function, default: () => {} },
    checked: { type: Array, required: true },
    disabled: { type: Boolean, default: () => false },
    type: { type: String, default: "" },
    withSearch: { type: Boolean, default: true },
    list: { type: Array, required: true },
    placeholder: { type: String, default: "" },
    onChange: {
      type: Function,
      required: true
    },
    width: {
      type: String,
      default: "300px"
    }
  },
  // emits: ["onChange"],
  data() {
    return {
      selected: [],
      filteredCountriesList: this.list
    };
  },

  computed: {
    selectedList() {
      return this.list.filter(({ id }) =>
        this.selected.filter(s => s.id === id)
      );
    },
    getfilteredCountriesList() {
      return this.filteredCountriesList;
    }
  },

  watch: {
    list: {
      handler(data) {
        this.filteredCountriesList = data;
      },
      deep: true
    }
  },
  methods: {
    selectHandler(id) {
      const isExist = this.selected.find(itemId => itemId === id);
      if (isExist) {
        this.selected = this.selected.filter(itemId => itemId !== id);
      } else {
        this.selected.push(id);
      }

      this.onChange(this.selected);
    },

    searchHandler(query) {
      if (typeof query !== "string") {
        return false;
      }
      this.filteredCountriesList = query.trim().length
        ? this.list.filter(({ name, email, offering_title }) => {
            const field = name ?? email ?? offering_title;
            return field
              .toLocaleLowerCase()
              .includes(query.toLocaleLowerCase());
          })
        : this.list;
    }
  }
});
</script>
