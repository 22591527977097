import { ITableHeadCell } from "@/components/Table/ts/interfaces/TableStructure";

export function generatePromoCodesHead(
  currentSort: any,
  currentSortKeyLocationName: string
): ITableHeadCell[] {
  const { byTitle } = currentSort;

  return [
    {
      size: "fluid",
      label: currentSortKeyLocationName,
      id: "location",
      sort: {
        keyName: byTitle.keyName,
        order: byTitle.order
      }
    },
    {
      size: "fluid",
      label: "Valid Till To",
      id: "valid"
    },
    {
      size: "md",
      label: "Type",
      id: "type"
    },
    {
      size: "smL",
      label: "Used",
      id: "used"
    },
    {
      size: "smL",
      label: "status",
      id: "status"
    },
    {
      size: "xs",
      label: "",
      id: "actions"
    }
  ];
}
