import { useStore } from "vuex";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import moment from "moment";
import { CreateRefundDto } from "@/store/interfaces/payments/CreateRefund.dto";
import { formatDateForGroup } from "@/hooks/formatingDate";

interface Options {
  viewConfig: ITableViewConfiguration;
  filter: any;
}
export function formatDate(date: string) {
  const result = moment(date)
    .local()
    .format("D.MM.YYYY");
  return `${result} at ${moment(date)
    .local()
    .format("h:mm a")}`;
}

export function formatDateUntill(date: string) {
  const result = moment(date)
    .local()
    .format("D.MM.YYYY");
  return `${result} ${moment(date)
    .local()
    .format("h:mm a")}`;
}

export function usePayments(options: Options) {
  const { viewConfig, filter } = options;
  const store = useStore();
  async function requestPaymentsHandler() {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("fetchPaymentsList", {
      viewConfig,
      filter
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
  }
  function groupByDate(tableBody: any) {
    const datedIds = [] as any;
    for (const item of tableBody) {
      const formattedItem = formatDateForGroup(
        moment(item?.date || item?.added).format("D MMM YYYY")
      );
      if (datedIds?.[formattedItem] && datedIds?.[formattedItem]?.length) {
        datedIds[formattedItem].push({
          ...item,
          date: formatDate(item?.date || item?.added)
        });
      } else {
        datedIds[formattedItem] = [
          {
            ...item,
            date: formatDate(item?.date || item?.added)
          }
        ];
      }
    }
    return Object.entries(datedIds);
  }

  async function changeViewConfigurationHandler({
    page,
    perPage
  }: ITableViewConfiguration): Promise<void> {
    if (page === viewConfig.page && perPage !== viewConfig.perPage) {
      viewConfig.page = 1;
    } else {
      viewConfig.page = page;
    }
    viewConfig.perPage = perPage;
    requestPaymentsHandler();
  }

  async function createRefund(data: CreateRefundDto): Promise<boolean> {
    await store.dispatch("setAppLoading", true);

    const { success, message } = await store.dispatch("createRefund", { data });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
    await store.dispatch("setAppLoading", false);
    return success;
  }

  async function changeFilterHandler({
    type,
    from,
    to
  }: {
    type: string;
    from: string;
    to: string;
  }) {
    filter.type = type;
    filter.from = from;
    filter.to = to;
    requestPaymentsHandler();
  }

  return {
    requestPaymentsHandler,
    formatDate,
    formatDateForGroup,
    groupByDate,
    changeViewConfigurationHandler,
    createRefund,
    changeFilterHandler
  };
}
