import moment from "moment";

export function formatDate(date: string) {
  const result = moment(date)
    .local()
    .format("D.MM.YYYY");
  return `${result} at ${moment(date)
    .local()
    .format("h:mm a")}`;
}

export function formatDateForGroup(date: string) {
  if (moment(date).isSame(moment(), "day")) {
    return "Today";
  }
  if (moment(date).isSame(moment().subtract(1, "day"), "day")) {
    return "Yesterday";
  }
  return moment(date).format("D MMM YYYY");
}

export function formateLLTime(date: string) {
  return moment(date).format("ll");
}

export function formateLTTime(date: string) {
  return moment(date).format("LT");
}
